import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

// Configurazione di Supabase (chiavi in chiaro)
const supabaseUrl = 'https://qsdkfkfwzcanmdbjvxvp.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFzZGtma2Z3emNhbm1kYmp2eHZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE3NTk1OTksImV4cCI6MjAzNzMzNTU5OX0.VNQKIxlaOQvxYqjGhQQL_1YX6fNIfmWEuI7mpmZfiMo';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const SocialFarming = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({ provider: 'twitter' });
    if (error) alert('Errore durante il login con Twitter.');
  };

  const saveUserData = async (session) => {
    const twitterId = session.user.id;
    const twitterUsername = session.user.user_metadata.preferred_username || session.user.user_metadata.full_name;
    const email = session.user.email;

    await supabase.from('twitter_users').insert({
      twitter_id: twitterId,
      twitter_username: twitterUsername,
      email: email,
    });
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setIsLoggedIn(false);
      setProfileImage(null);
      setUsername(null);
    }
  };

  useEffect(() => {
    const checkUserSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setIsLoggedIn(true);
        setProfileImage(session.user.user_metadata.avatar_url);
        setUsername(session.user.user_metadata.preferred_username || session.user.user_metadata.full_name);
        await saveUserData(session);
      }
    };
    checkUserSession();
  }, []);

  return (
    <div id='socialFarming' className="sectionContainer2">
      <h1>Social Farming</h1>
      <p>Login with X to earn points!</p>

      <div style={{ margin: '20px 0' }}>
        {!isLoggedIn ? (
          <button onClick={handleLogin}>Login con Twitter</button>
        ) : (
          <button onClick={handleLogout}>Disconnettiti</button>
        )}
      </div>

      {isLoggedIn && profileImage && (
        <div style={{ marginTop: '20px' }}>
          <h3>Benvenuto, {username}!</h3>
          <img src={profileImage} alt="Profile" style={{ width: '100px', borderRadius: '50%' }} />
        </div>
      )}
    </div>
  );
};

export default SocialFarming;