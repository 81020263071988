import React from "react";
import './footer.css';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";

export function Footer() {
    return(
        <div id="contacts" className="footerContainer">
            <div className="footerSection">
                <span style={{textTransform: 'uppercase', fontSize: '0.7rem'}}>location: </span>
                <span style={{textTransform: 'uppercase', fontSize: '0.7rem'}}>Metaverse, WWW, 00110010</span>
            </div>
            <div className="footerSection">
                <a href="https://x.com/TheItalianCoin" target="_blank">
                <FaSquareXTwitter style={{fontSize: '1.3rem', padding: '0 0.2rem'}}/>
                </a>
                <a href="https://t.me/+UQpXMWr4BCEzNzE0" target="_blank">
                    <FaTelegram style={{fontSize: '1.3rem', padding: '0 0.2rem'}}/>
                </a>
            </div>
            <div className="footerSection">
                <a href="mailto:theitaliancoin@gmail.com">theitaliancoin@gmail.com</a>
            </div>
        </div>
    )
}