import React from "react";
import { IoStar } from "react-icons/io5";

export function Separator ({color}) {
    return(
        <div className="separator">
            <div style={{backgroundColor: color}} className="separatorLine"></div>
            <IoStar style={{fontSize: 30}}/>
            <div  style={{backgroundColor: color}} className="separatorLine"></div>
        </div>
    )
}