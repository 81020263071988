import { useRef, useState } from 'react';
import './App.css';
//Components import
import { Header } from './Component/Header/Header';
import { Banner } from './Component/Header/Banner';
import { BuySection } from './Component/Buy/BuySection';
import { SocialFarming } from './Component/SocialFarming/SocialFarming';
import { Tokenomics } from './Component/Tokenomics/Tokenomics';
import { Roadmap } from './Component/Roadmap/Roadmap';
import { Footer } from './Component/Footer/Footer';


function App() {
  const scrollRef = useRef();
  const [scrollPos, setScrollPos] = useState(0);
  function handleScroll() {
    if(scrollRef.current) {
      setScrollPos(scrollRef.current.scrollTop);
    }
  };


  return (
    <div 
    ref={scrollRef}
    style={{height: '100vh', overflow:'scroll'}}
    onScroll={() => handleScroll()}
    className="App">
      <Header scrollPos={scrollPos} />
      <Banner />
      <SocialFarming />
      <BuySection />
      <Tokenomics />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
