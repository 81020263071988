import React, { useEffect, useState } from "react";
import './header.css';
import { motion, useSpring } from "framer-motion";


export function Header({scrollPos}) {

    const sections = [
       {title: 'Social Farming', ref: '#socialFarming'},
       {title: 'Play to Airdrop', ref: '#howtobuy'},
       {title: 'Tokenomics', ref: '#tokenomics'},
       {title: 'Roadmap', ref: '#roadmap'},
       {title: 'Contacts', ref: '#contacts'}
    ];

    const heightValue = useSpring(150);
    const spring = useSpring(heightValue, { stiffness: 1000, damping: 150 })
    const top = useSpring(0);
    const bottom = useSpring(0);

    useEffect(() => {
        setIsOpen(false);
        if(scrollPos <= 100) {
            heightValue.set(150);
        } 
        if(scrollPos > 100) {
            heightValue.set(32);
        }
    },[scrollPos]);

    const [isOpen, setIsOpen] = useState(false);

    
    return(
        <motion.div style={{height: spring}} 
        className="headerContainer">
            <motion.h1 className='headerTitle'>The Italian Coin</motion.h1>
            <div className="headerNav">
               {sections.map((el, index) => (
                <div key={index} className="navTile">
                    <a href={el.ref}>
                    <span className="navLink">{el.title}</span>
                    </a>
                </div>
               ))}
            </div>
            <div
            onClick={() => setIsOpen(!isOpen)}
            className="HambugherContainer">
                <div className="bar" style={{transform: isOpen ? 'rotate(45deg)  translate(4px,5px)' : 'rotate(0deg) translate(0px,0px)', transition: '0.3s'}}></div>
                <div style={{opacity: isOpen ? 0 : 1, transition: '0.3s'}} className="bar"></div>
                <div className="bar" style={{transform: isOpen ? 'rotate(-45deg) translate(4px,-5px)' : 'rotate(0deg) translate(0px,0px)', transition: '0.3s'}}></div>
            </div>
            {
                isOpen && 
                <motion.div
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                className="mobileNav">
                {sections.map((el, index) => (
                    <div key={index} className="navTile">
                        <a href={el.ref}>
                        <span className="navLink">{el.title}</span>
                        </a>
                    </div>
                ))}
                </motion.div>
            }
        </motion.div>
    )
}
