import React from "react";
import { Separator } from "../Header/Separator";
import './tokenomics.css';

export function Tokenomics() {
    return(
        <div id="tokenomics" className="sectionContainer2">
            <h2>Tokenomics</h2>
            <Separator />
        
            <ul>
                <li>Details Soon!</li>
            </ul>

        </div>
    )
}
