import { useSDK } from "@metamask/sdk-react";
import React, {useEffect, useState} from "react";
import './buy.css';
import { Separator } from "../Header/Separator";
import blastlogoNOBG from '../../media/blastlogoNOBG.png';
import blastscanlogo from '../../media/platformer.png';
import metamasklogo from '../../media/metamasklogo.png';
import telegramlogo from '../../media/telegramlogo.png';
import dexlogo from '../../media/snake.png';
import blastlogo from '../../media/blastlogo.png';
import districtlogo from '../../media/districtlogo.png';
import telegramlogo2 from '../../media/telegramlogo2.png';
//functions import
import { addBlast } from "./addBlast";


export function BuySection() {


     

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream;
    };

    const [account, setAccount] = useState(null);
    const [metaAccount, setMetaAccount] = useState(null);

  // Function to connect to the user's wallet
  const connectWallet = async () => {

    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        console.error("User rejected the request.");
      }
    } else {
      console.error("Please install MetaMask!");
      window.alert('Please install Metamask!')
    }
  };
  const connectMetamaskMobile = (tokenAddress, tokenSymbol, tokenDecimals) => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    const metamaskAppDeepLink =  'https://metamask.app.link/dapp/'+dappUrl //`metamask://add-token?address=${tokenAddress}&symbol=${tokenSymbol}&decimals=${tokenDecimals}`;
    window.open(metamaskAppDeepLink, "_self");
  };

  // Function to add token to the network
  const addTokenToNetwork = async () => {
    const tokenAddress = "0x53BEbF7995a81Eb35c855F45c89962ED6A19d9ff";
    const tokenSymbol = "TIC"; 
    const tokenDecimals = 18; 
        if(!window.ethereum && isMobile(tokenAddress, tokenSymbol, tokenDecimals)) {
            connectMetamaskMobile()
        } else {
        if (!window.ethereum) {
        console.error("Ethereum object doesn't exist!");
        return;
        }


        try {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
            type: 'ERC20',
            options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
            },
            },
        });

        if (wasAdded) {
            console.log('Token was added!');
        } else {
            console.log('Token not added');
        }
        } catch (error) {
        console.error(error);
        window.alert('Please install Metamask.')
        }
    }
    };
  function handleMetaMask() {
        if(!account) {
            connectWallet();
        }
        if(account) {
            addTokenToNetwork();
        }
    }


      const actions = [
        {title: 'Play Now', type: 'link', payload: 'https://t.me/ItalianTap_bot', icon: telegramlogo},
        {title: 'Join Telegram', type: 'link', payload: 'https://t.me/ItalianTap', icon: telegramlogo2},
        //{title: 'Join DistrictOne', type: 'link', payload: 'https://districtone.io/invite/ITALY', icon: districtlogo}, 
       // {title: 'Add Blast Network', type: 'function', payload: addBlast, icon: blastlogoNOBG},
        //{title: 'Bridge to Blast', type: 'link', payload: 'https://blast.io/07BJ0', icon: blastlogo},
        //{title: 'Add to Metamask', type: 'function', payload: handleMetaMask, icon: metamasklogo},
        //{title: 'Follow Us', type: 'link', payload: 'https://x.com/TheItalianCoin', icon: x},
        //{title: 'Join DistrictOne', type: 'link', payload: 'https://districtone.io/invite/ITALY', icon: districtlogo}, 
        // {title: 'Play Vipera', type: 'link', payload: 'https://theitaliancoin.github.io/flappy/', icon: dexlogo},
       //{title: 'Play Saltarello', type: 'link', payload: 'https://theitaliancoin.github.io/vipera/', icon: blastscanlogo},
    ]


    return(
        <div id="howtobuy" className="buyContainer">
            <h2 >Play to Airdrop</h2>
            <Separator />
            <div className="tilesContainer">
                {
                    actions.map((el,index) => (
                        <div key={index} 
                        className="tile">
                            {
                                el.type === 'link' &&

                                <a href={el.payload} target="_blank">
                                <div 
                                onClick={() => el.payload}
                                style={{backgroundImage: `url(${el.icon})` }}
                                className="functionTile">            
                                    <div className="inner">
                                        <span className="tileTitle">{el.title}</span>
                                    </div>
                                </div>
                                </a>
                            }
                            {
                                el.type === 'function' && 
                                <div 
                                onClick={el.payload}
                                style={{backgroundImage: `url(${el.icon})` }}
                                className="functionTile"> 
                                    <div className="inner">
                                        <span className="tileTitle">{el.title}</span>
                                    </div>                            
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
