import React from "react";
import './header.css';
import copytic from '../../media/copytic.png';
import { Separator } from "./Separator";
import giflogo from '../../media/giflogo.gif';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import districtlogo from '../../media/districtlogo.png';

export function Banner () {
    return(
        <div className="bannerContainer">
            <h1>The Italian Coin</h1>
            <img src={giflogo} id="bannerImg" />
            <Separator color={'black'} />
            <span>Italian WTF Memecoin</span>
            <div style={{paddingTop: '2rem'}} className="footerSection">
                <a href="https://x.com/ItalianTap" target="_blank">
                <FaSquareXTwitter style={{fontSize: '1.7rem', padding: '0 0.2rem', color:'black'}}/>
                </a>
                <a href="https://t.me/ItalianTap" target="_blank">
                    <FaTelegram style={{fontSize: '1.7rem', padding: '0 0.2rem', color:'black'}}/>
                </a>
                    </div>
        </div>
    )
}
