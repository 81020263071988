import React from "react";
import { Separator } from "../Header/Separator";

export function Roadmap() {
    return(
        <div id='roadmap' className="sectionContainer1">
            <h2>Roadmap</h2>
            <Separator color={'black'}/>
            <ul>
                <li> September 2024 | Alpha App Launch and Bug Fix!</li>
                <li> October  2024 | Marketing and development!</li>
                <li> November 2024 | Beta Launch and Gambling</li>
                <li> Dicember 2024 | Airdrop!</li>
            </ul>
        </div>
    )
}
